import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import tw from "twin.macro";
import { css } from "@emotion/css";
import { StaticImage } from "gatsby-plugin-image";
import { Popover, Transition } from "@headlessui/react";
import {
  MenuIcon,
  XIcon,
  CalendarIcon as ArrowIcon,
} from "@heroicons/react/outline";
import styles from "./styles";

const menu = [
  "therapist",
  "therapies",
  "videos",
  "prices",
  "testimonials",
  "contacts",
];

const NavItem = ({ title, href }: { title: string; href: string }) => {
  return (
    <a
      href={href}
      tw="lg:text-lg font-medium text-white hover:(border-white) border-b border-transparent transition duration-500 ease-in-out"
    >
      {title}
    </a>
  );
};

const Header = ({ t, lang }: { t: Record<string, string>; lang: string }) => {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 0);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Popover css={styles.header({ sticky })}>
      {({ open }) => (
        <>
          <div tw="container mx-auto px-4 sm:px-6 xl:px-0">
            <div tw="flex justify-between items-center py-6 lg:justify-start lg:space-x-10">
              <div tw="flex justify-start lg:w-0 lg:flex-1">
                <a href="#home" title={t.logoAlt}>
                  <span tw="sr-only">{t.title}</span>
                  <StaticImage
                    src="../../../images/logo.png"
                    alt={t.logoAlt}
                    placeholder="blurred"
                    layout="fixed"
                    height={48}
                    tw="h-8 w-auto sm:h-10"
                  />
                </a>
              </div>
              <div tw="-mr-2 lg:hidden flex items-center">
                <Link
                  title={
                    lang === "pt" ? "English version" : "Versão em Português"
                  }
                  to={lang === "pt" ? "/en" : "/"}
                  tw="whitespace-nowrap pr-4 mt-1 text-2xl"
                >
                  {t.changeLangButton}
                </Link>
                <Popover.Button tw="p-2 inline-flex items-center justify-center text-gray-400 focus:outline-none">
                  <span tw="sr-only">Open menu</span>
                  <MenuIcon tw="h-6 w-6 text-white" aria-hidden="true" />
                </Popover.Button>
              </div>
              <nav tw="hidden lg:flex lg:space-x-6 xl:space-x-10">
                {menu.map((menuItem, index) => (
                  <NavItem
                    key={index}
                    title={t[menuItem]}
                    href={`#${menuItem}`}
                  />
                ))}
              </nav>
              <div tw="hidden lg:flex items-center justify-end lg:flex-1 lg:w-0">
                <Link
                  title={
                    lang === "pt" ? "English version" : "Versão em Português"
                  }
                  to={lang === "pt" ? "/en" : "/"}
                  tw="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 text-2xl"
                >
                  {t.changeLangButton}
                </Link>
                <a
                  href="#contacts"
                  tw="rounded-sm ml-4 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 shadow text-white bg-primary transition duration-500 ease-in-out"
                >
                  <span>{t.cta}</span>
                  <ArrowIcon tw="ml-1 h-5 w-5 text-white" />
                </a>
              </div>
            </div>
          </div>
          <Transition
            show={open}
            enter={css(tw`transition ease-in duration-100`)}
            enterFrom={css(tw`transform opacity-0`)}
            enterTo={css(tw`transform opacity-60`)}
            leave={css(tw`transition ease-out duration-100`)}
            leaveFrom={css(tw`transform opacity-60`)}
            leaveTo={css(tw`transform opacity-0`)}
            tw="opacity-60 fixed inset-0 bg-black"
          >
            <Popover.Overlay />
          </Transition>
          <Transition
            show={open}
            enter={css(tw`transition-all ease-in-out duration-500`)}
            enterFrom={css(tw`transform top-[-376px]`)}
            enterTo={css(tw`transform top-0`)}
            leave={css(tw`transition-all ease-in-out duration-500`)}
            leaveFrom={css(tw`transform top-0`)}
            leaveTo={css(tw`transform top-[-376px]`)}
            tw="absolute top-0 inset-x-0 bg-black"
          >
            <Popover.Panel focus static>
              <div tw="flex justify-between px-4 md:px-6 py-6 bg-black">
                <Popover.Button
                  as="a"
                  href="#home"
                  title={t.logoAlt}
                  tw="flex justify-start lg:w-0 lg:flex-1"
                >
                  <span tw="sr-only">{t.title}</span>
                  <StaticImage
                    src="../../../images/logo.png"
                    alt={t.logoAlt}
                    placeholder="blurred"
                    layout="fixed"
                    height={48}
                    tw="h-8 w-auto sm:h-10"
                  />
                </Popover.Button>
                <Popover.Button tw="bg-black right-0 focus:outline-none">
                  <span tw="sr-only">{t.closeMenu}</span>
                  <XIcon tw="h-6 w-6 text-white" aria-hidden="true" />
                </Popover.Button>
              </div>
              <div tw="py-6 px-5 md:px-7 space-y-6">
                <div tw="grid grid-cols-2 gap-10">
                  {menu.map((menuItem, index) => (
                    <Popover.Button
                      key={index}
                      as="a"
                      href={`#${menuItem}`}
                      tw="text-base font-medium text-white"
                    >
                      {t[menuItem]}
                    </Popover.Button>
                  ))}
                  <Link to={lang === "pt" ? "/en" : "/"} tw="text-2xl">
                    {t.changeLangButton}
                  </Link>
                  <Popover.Button
                    as="a"
                    href="#contacts"
                    tw="rounded-sm whitespace-nowrap inline-flex w-min items-center justify-center px-4 py-2 shadow text-white bg-primary transition duration-500 ease-in-out"
                  >
                    <span>{t.cta}</span>
                    <ArrowIcon tw="ml-1 h-5 w-5 text-white" />
                  </Popover.Button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Header;
