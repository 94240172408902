import tw from "twin.macro";

export const Container = tw.footer`
  text-gray-600 container mx-auto px-4
  md:px-6 pt-10
  xl:px-0
`;

export const LinksContainer = tw.div`
  pb-8 mx-auto flex flex-col items-center
  sm:flex-row
`;
