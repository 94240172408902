import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Container, LinksContainer } from "./styles";

const Footer = ({ t, lang }: { t: Record<string, string>; lang: string }) => {
  return (
    <Container>
      <LinksContainer>
        <Link
          to={lang === "pt" ? "/#home" : "/en/#home"}
          tw="inline-flex items-center justify-center py-2"
          title={t.logoAlt}
        >
          <StaticImage
            src="../../../images/logo-black.png"
            alt={t.logoAlt}
            placeholder="blurred"
            layout="fixed"
            height={40}
            tw="h-10 w-auto"
          />
        </Link>
        <p tw="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
          © {new Date().getFullYear()} {t.site}
        </p>
        <span tw="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start">
          <a
            href={t.instagram}
            target="_blank"
            rel="noopener noreferrer nofollow"
            tw="ml-3 text-gray-500 flex gap-2 items-center"
          >
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              tw="w-5 h-5"
              viewBox="0 0 24 24"
            >
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
            </svg>
            <span>Instagram</span>
          </a>
        </span>
      </LinksContainer>
    </Container>
  );
};

export default Footer;
