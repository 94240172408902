import * as React from "react";
import { Helmet } from "react-helmet";

const SEO = ({ t, lang }: { t: Record<string, string>; lang: string }) => {
  return (
    <Helmet title={t.title} htmlAttributes={{ lang }}>
      <meta name="title" content={t.title} />
      <meta name="description" content={t.description} />
      <meta name="image" content={t.image} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={t.url} />
      <meta property="og:title" content={t.title} />
      <meta property="og:description" content={t.description} />
      <meta property="og:image" content={t.image} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={t.url} />
      <meta property="twitter:title" content={t.title} />
      <meta property="twitter:description" content={t.description} />
      <meta property="twitter:image" content={t.image} />
    </Helmet>
  );
};

export default SEO;
