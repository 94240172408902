import { css } from "@emotion/react";

export const customStyles = css`
  @font-face {
    font-family: "BunyaLight";
    src: url("/fonts/BunyaLight.eot");
    src: url("/fonts/BunyaLight.eot?#iefix") format("embedded-opentype"),
      url("/fonts/BunyaLight.woff2") format("woff2"),
      url("/fonts/BunyaLight.woff") format("woff"),
      url("/fonts/BunyaLight.ttf") format("truetype"),
      url("/fonts/BunyaLight.svg#BunyaLight") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  html {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
  }

  body {
    background-color: #fff;
    font-family: "Inconsolata", Helvetica, Arial, sans-serif;
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "BunyaLight", Helvetica, Arial, sans-serif;
  }

  section {
    scroll-margin-top: 96px;
  }

  .text-shadow {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.52);
  }

  .swiper-pagination-bullet-active {
    background-color: #406151 !important;
  }
`;
